import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';
// import BlogSection from 'common/source/containers/BlogSection';
// import TimelineSection from 'common/source/containers/TimelineSection';
// import ContactSections from 'common/source/containers/Contact';
// import PartnerSection from 'common/source/containers/Partner';
// import FeatureSection from 'common/source/containers/FeatureSection';
import CTABanner from 'common/source/containers/CTABanner';
import Footer from 'common/source/containers/Footer';
// import ContactView from 'common/source/containers/ContactView';

import SEO from '../components/SEO';
import config from '../../data/SiteConfig';
import SolarPanelsView from '../components/Catalog/Solar/SolarPanels';

class PanelesSolaresRootPage extends React.Component {
  render() {
    const { data } = this.props;
    const regions = data.regions.edges.map(edge => edge.node);
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO pageTitle={`Paneles Solares | ${config.siteTitle}`} path="/paneles-solares" />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar />
            <SolarPanelsView regions={regions} />
            <CTABanner />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default PanelesSolaresRootPage;

/* eslint no-undef: "off" */
export const panelesSolaresQuery = graphql`
  query PanelsRegions {
    regions: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "REGION-PANELS" } } }
      sort: { order: ASC, fields: [frontmatter___region_order] }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            region
            PSH
            region_order
            type
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
